
import Vue from "vue";
import { mapActions } from "vuex";
import Alertize from "../../../../components/Alertize.vue";
import CustomListForm from "../CustomListForm.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "ListCreate",
	props: {},
	components: { Alertize, CustomListForm },
	data: () => ({
		title: "Create",
		message: "",
		type: "info",
		valid: true,
		custom_list: {
			name: "",
			custom_list_type_id: null,
			default_radius_km: null,
			active: true
		}
	}),
	created() {},
	mounted() {},
	computed: {},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},
		async handleSubmit(params: { data: any }) {
			if (!(await this.validate())) return;
			this.custom_list = params.data;
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchCreateCustomList()
			.then((response) => {
				this.setLoadingData();
			})
			.catch(err => {
				this.setLoadingData();
			});
			await this.setLoadingData();
		},
		handleCancel() {
			this.$router.push({ name: "CustomListIndex" });
		},
		async dispatchCreateCustomList() {
			return this.$store.dispatch(
				"custom_list/create",
				this.custom_list,
				{ root: true }
			);
		}
	}
});
